import { useRootLoaderData } from '~/utils/root-loader.js';

function isUser(user: any): user is ReturnType<typeof useRootLoaderData>['user'] {
  return user && typeof user === 'object' && typeof user.id === 'string';
}

export function useOptionalUser() {
  const data = useRootLoaderData();
  if (!data || !data.user || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function useUser() {
  const maybeUser = useOptionalUser();
  if (!maybeUser) {
    throw new Error('No user found in app loader');
  }
  return maybeUser;
}

export function useIsAuthenticated() {
  const data = useRootLoaderData();
  return !!data.session;
}
